import ReactModal from 'react-modal'
import { useState } from 'react';
import { css } from "@emotion/react";
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getAll, CreateKey } from '../../../services/pix.service';
import { MessageToCache, MethodToCache, PullerGetAllPixKeyList, KeyDataToCache } from '../../../services/puller.service';
import { useNavigate } from 'react-router';
import { apiV1 } from "../../../lib/axios";
import { formatCnpjCpf, formatPhone } from "../../../utils/formatter";

import './styles.css'
import { FeedBackKeyCreated } from './FeedBackKeyCreated';
import { CreatePix } from './CreatePix';
import { ValidateConfirmationCode } from './ValidateConfirmationCode';
import { FeedBackKeyDeleted } from './FeedBackKeyDeleted';

const KEY_TYPE = {
  CPF: 0,
  CNPJ: 1,
  MAIL: 2,
  PHONE: 3,
  RANDOM: 4,
}

export function KeysModal({ isOpen, setKeysModalIsOpen, refreshKeys, setLoaderParent }) {
  const [keyIsCreated, setKeyIsCreated] = useState(false)
  const [isWaitDBChange, setIsWaitDBChange] = useState(false);
  const [rows, setRows] = useState([]);
  const [inactiveKeys, setInactiveKeys] = useState([]);
  const [loader, setLoader] = useState(false);
  const [typeKey, setTypeKey] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isPF, setIsPF] = useState(null);
  const [showValidateConfirmationCodeComponent, setShowValidateConfirmationCodeComponent] = useState(false);
  const [showKeyDeletedFeedback, setShowKeyDeletedFeedback] = useState(false);


  const navigate = useNavigate()

  function handleCloseModal() {
    setKeyIsCreated(false);
    CloseConfirmationCodeModal();
    CloseKeyDeletedModal();
    setKeysModalIsOpen(false);
  }

  function StartPuller() {
    setLoader(true);
    setLoaderParent(true);
    MethodToCache(setIsWaitDBChange);
    MessageToCache("Lista de chaves atualizada com sucesso!");
    KeyDataToCache(rows)
    PullerGetAllPixKeyList();
  }

  function handleCreateNewKey(e) {
    setLoader(true)
    e.preventDefault()
    try {
      CreateKey(typeKey, '', navigate)
      .then(response => {
        if(response.isCreated){
            setKeyIsCreated(true);
            StartPuller();
          }
        });
    } catch (error) {
      setKeyIsCreated(false)
      setLoader(false)
      toast.error('Erro ao solicitar nova chave');
    }
  }

  const getKeys = async () => {
    if (localStorage.getItem("taxNumber") == "12345678900") {
      setRows([
        {
          id: 259,
          personId: 332,
          type: "4",
          key: "ae42d3d8-b36c-4a8e-awc9-d89f6d43fb9e",
          status: "0",
          createdAt: "2023-09-26 13:47:00",
          updatedAt: "2023-09-26 13:47:00",
        },
        {
          id: 224,
          personId: 332,
          type: "2",
          key: "teste@teste.com.br",
          status: "2",
          createdAt: "2023-08-01 13:30:00",
          updatedAt: "2023-08-09 13:13:48",
        },
      ]);
      return;
    }
    setLoader(true);

    try {
      const response = await getAll();
      setRows(response.filter(i => i.status != PIX_KEY_STATUS_LIST.INACTIVE));
      setInactiveKeys(response.filter(i => i.status == PIX_KEY_STATUS_LIST.INACTIVE));
      setLoader(false);
      refreshKeys();
    } catch (error) {
      toast.error("Erro ao recuperar as chaves, tente novamente");
      setLoader(false);
    }
  };

  useEffect(()=>{
    if(!userData){
      GetUserDataFromBack();
    }

  }, []);

  useEffect(() => {
    getKeys();
    if(isWaitDBChange){
      setIsWaitDBChange(false);
    }
  }, [isWaitDBChange]);

  async function GetUserDataFromBack(){
    const endpoint = "/person/show";
    const response = await apiV1.get(endpoint);
    const { phoneNumber, taxNumber, mail } = response.data.data;
    checkIfIsPF(taxNumber);
    const taxNumberFormatted = formatCnpjCpf(taxNumber);
    const phoneNumberFormatted = formatPhone(phoneNumber);
    setUserData({phoneNumber: phoneNumberFormatted, taxNumber: taxNumberFormatted, mail});
  }

  function checkIfIsPF(taxNumber){
    if(taxNumber.length === 11){
      setIsPF(true);
    }else {
      setIsPF(false);
    }
  }
  function ShowConfirmationCodeModal(){
    setShowValidateConfirmationCodeComponent(true);
  }
  function CloseConfirmationCodeModal() {
    setShowValidateConfirmationCodeComponent(false);
  }
  function ShowKeyDeletedModal(){
    setShowKeyDeletedFeedback(true);
  }
  function CloseKeyDeletedModal() {
    setShowKeyDeletedFeedback(false);
  }
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 3em;
  `;

  return (
    <ReactModal
      isOpen={isOpen}
      className={"keys-modal"}
      overlayClassName={"overlay-keys-modal"}
    >
      {keyIsCreated && (<FeedBackKeyCreated handleCloseModal={handleCloseModal} keyType={typeKey}/>)}
      {showKeyDeletedFeedback && (<FeedBackKeyDeleted handleCloseModal={handleCloseModal} keyType={typeKey}/>)}
      {(!keyIsCreated && !showValidateConfirmationCodeComponent && !showKeyDeletedFeedback) && (
          <CreatePix
            handleCloseModal={handleCloseModal}
            userData={userData}
            loader={loader}
            rows={rows}
            getKeys={getKeys}
            override={override}
            handleCreateNewKey={handleCreateNewKey}
            typeKey={typeKey}
            setTypeKey={setTypeKey}
            showConfirmationCodeModal={ShowConfirmationCodeModal}
            showKeyDeletedModal={ShowKeyDeletedModal}
            closeConfirmationCodeModal={CloseConfirmationCodeModal}
            startPuller={StartPuller}
            inactiveKeys={inactiveKeys}
            isPF={isPF} />

        )}
      {showValidateConfirmationCodeComponent && (<ValidateConfirmationCode startPuller={StartPuller} handleCloseModal={handleCloseModal} typeKey={typeKey}/>)}
    </ReactModal>
  )
}

const PIX_KEY_STATUS_LIST = {
  ACTIVE: 0,
  INACTIVE: 1,
  PENDING: 2,
  ERROR: 3,
  PORTABILITY_ERROR: 4,
  OWNERSHIP_ERROR: 5,
  NOT_CONFIRMED: 6,
  CLAIMING: 7,
  EXTERNAL_CLAIMING : 8,
}
